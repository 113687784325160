import React from 'react';
import './MainContent.css';

const imagesContext = require.context('./images', true);
const imagePaths = imagesContext.keys();

const artPieces = imagePaths.map(path => {
    const label = path.replace('./', '').replace(/\.\w+$/, '');
    return {
        src: imagesContext(path),
        label
    };
});

const MainContent = ({ searchTerm }) => {
    const filteredArtPieces = artPieces.filter(piece =>
        piece.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="MainContent">
            {filteredArtPieces.map((piece, index) => (
                <div className="art-piece" key={index}>
                    <img className="art-image" src={piece.src.default || piece.src} alt={piece.label} />
                    <div className="art-label">@{piece.label}</div>
                    <a href={piece.src.default || piece.src} download={piece.label} className="download-button">Download</a>
                </div>
            ))}
        </div>
    );
};

export default MainContent;
