import React, { useState } from 'react';
import './App.css';
import SearchBar from './components/SearchBar';
import MainContent from './components/MainContent';

function App() {
    const [searchTerm, setSearchTerm] = useState('');

    return (

        <div className="App">
            <header className="App-header">
                <SearchBar onSearch={setSearchTerm}/>
            </header>
            <div className="App-body">
                <MainContent searchTerm={searchTerm}/>
            </div>
        </div>
    );
}

export default App;
